import React from 'react'
import {
  Box,
  Typography,
  Paper,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from '@mui/material'
import { PlanInput, PhoneNumberInput } from './PlanValue'
import { toast } from 'react-toastify'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { useNotifyMutation } from '../../redux/api/solicitationApi'

interface Props {
  opw_offer_id?: string
  years: number
  brand?: string
}

export const PaymentNoticeCard = ({ opw_offer_id, years, brand }: Props) => {
  const [inputValue, setInputValue] = React.useState('')
  const [verifyValue, setVerifyValue] = React.useState('')
  const [submitBtnDisabled, setSubmitBtnDisabled] = React.useState(false)
  const [option, setOption] = React.useState('email')
  const [notify, { isLoading, isSuccess, isError, error }] = useNotifyMutation()

  React.useEffect(() => {
    if (isSuccess) {
      toast.success('Success!')
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        const errors = error as any
        errors?.data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((error as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
  }, [isLoading])

  const changeOption = (value: string) => {
    setInputValue('')
    setVerifyValue('')
    setOption(value)
  }

  const handleNotify = () => {
    if (option === 'message') {
      if (!isValidPhoneNumber(inputValue)) {
        toast.error('Please input valid number', {
          position: 'top-right',
        })
        return
      }
    } else if (option === 'email') {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      if (!emailRegex.test(inputValue)) {
        toast.error('Please input valid email', {
          position: 'top-right',
        })
        return
      }
    } else if (option === 'agent') {
      window.location.href = `https://protectmy.electrolux.com/offer/contract/view?opw_offer_id=${opw_offer_id}&years=${years}`
      return
    }
    notify({
      opw_offer_id,
      years,
      contact: inputValue,
      isEmail: option === 'email',
      brand,
    })
    setSubmitBtnDisabled(true)
    setTimeout(() => {
      setSubmitBtnDisabled(false)
    }, 30000)
  }

  return (
    <Paper sx={{ ...styles.cardContainer }}>
      <Typography sx={styles.planCardTitle}>Send Payment Notice</Typography>
      <Typography sx={styles.description}>
        Please confirm with the consumer if they would like to receive an email or text link to
        process payment. Verify the information, and click Submit to proceed
      </Typography>
      <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />
      <RadioGroup
        aria-labelledby='radio-buttons-group-label'
        value={option}
        onChange={(event) => changeOption(event.target.value)}
        name='radio-buttons-group'
        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
      >
        <FormControlLabel
          value='email'
          control={<Radio />}
          label={
            <Typography sx={{ ...styles.radioLabel, fontWeight: option == 'email' ? 700 : 400 }}>
              Email
            </Typography>
          }
        />
        <FormControlLabel
          sx={{ marginRight: '0px' }}
          value='message'
          control={<Radio />}
          label={
            <Typography sx={{ ...styles.radioLabel, fontWeight: option == 'message' ? 700 : 400 }}>
              Text Message
            </Typography>
          }
        />
        {/*<FormControlLabel
          sx={{ marginRight: '0px' }}
          value='agent'
          control={<Radio />}
          label={
            <Typography sx={{ ...styles.radioLabel, fontWeight: option == 'agent' ? 700 : 400 }}>
              Agent Assisted
            </Typography>
          }
        />*/}
      </RadioGroup>
      {option == 'email' ? (
        <Box>
          <PlanInput
            value={inputValue}
            label='Email:'
            onChange={(value) => setInputValue(value)}
            placeholder=''
          />
          <PlanInput
            value={verifyValue}
            label='Verify Email:'
            onChange={(value) => setVerifyValue(value)}
            placeholder=''
          />
        </Box>
      ) : option == 'message' ? (
        <Box>
          <PhoneNumberInput
            value={inputValue}
            label='Number:'
            onChange={(value) => setInputValue(value)}
            placeholder='(000) 000-0000'
          />
          <PhoneNumberInput
            value={verifyValue}
            label='Verify Number:'
            onChange={(value) => setVerifyValue(value)}
            placeholder='(000) 000-0000'
          />
        </Box>
      ) : null}
      <Box sx={{ ...styles.buttonContainer, marginTop: '20px' }}>
        <Button
          disabled={inputValue !== verifyValue || submitBtnDisabled}
          sx={styles.button}
          variant='contained'
          onClick={handleNotify}
        >
          {option === 'agent' ? 'Process' : 'Submit'}
        </Button>
      </Box>
    </Paper>
  )
}

const styles = {
  container: {
    paddingTop: '50px',
    paddingBottom: '50px',
    paddingLeft: '150px',
    paddingRight: '150px',
    backgroundColor: '#DFE2E6',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '70px',
  },
  cardContainer: {
    padding: '40px 30px',
    height: '420px',
  },
  planCardTitle: {
    fontFamily: 'Electrolux Sans',
    fontWeight: 700,
    fontSize: '32px',
    color: '#000',
    textAlign: 'center',
  },
  radioLabel: {
    fontFamily: 'Electrolux Sans',
    fontSize: '16px',
    color: '#040F3E',
  },
  buttonContainer: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    minWidth: '150px',
  },
  button: {
    height: '45px',
    width: '132px',
  },
  selectedMark: {
    backgroundColor: '#74BBCA',
    width: '102px',
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedLabel: {
    marginTop: '10px',
    fontFamily: 'Electrolux Sans',
    fontWeight: 700,
    fontSize: '24px',
    color: '#000',
    textAlign: 'center',
    textTransform: 'lowercase',
  },
  description: {
    fontFamily: 'Electrolux Sans',
    fontWeight: 400,
    fontSize: '13px',
    color: '#000',
    textAlign: 'center',
    marginTop: '24px',
  },
}
